

import { Collapse, Zoom, Fade, Grow, Slide } from '@material-ui/core';
import { ReactComponent as TShirt } from '../../statics/images/t-shirt.svg';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { makeStyles } from '@material-ui/core/styles';


import ExtendedNameFild from './ExtendedNameFild';

function Type4Scoreboard(props) {
    const localClasses = useStyles(props.config.theme);

    return (
        <>
            <Zoom in={(!props.config.innactive) && (props.config.gameStatusVisible || props.config.teamStatusVisible) && props.config.gameStatus !==  null} >
                <div className={localClasses.centerContainer}> 
                    <div className={props.config.styleClass.nameContainer}>  
                        {props.config.scoreboardBackVisible && 
                            <div className={props.config.styleClass.columnContainer}> 
                                <div className={props.config.styleClass.nameItemDiv}>
                                </div>
                                <object data={props.config.backImageUrl} className={props.config.styleClass.scoreboardBackImg} />
                            </div>
                        }
                        {props.config.showTshirt && 
                            <div className={props.config.styleClass.columnContainer}> 
                                <div className={props.config.styleClass.nameItemDiv}>
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamAColor}}></div>
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>  
                                    <div className={props.config.styleClass.tshirtDiv} style={{backgroundColor: props.config.teamBColor}}></div>
                                </div> 
                            </div>
                        }

                        <div className={props.config.styleClass.columnContainer} style={{flexGrow: 1}}> 
                            <div className={props.config.styleClass.nameItemDiv}>
                            </div>
                                
                            <div className={props.config.styleClass.nameItemDiv}>
                                <div className={props.config.styleClass.oddNameDiv + ' ' +localClasses.playerNameDiv} >
                                    <ExtendedNameFild name={props.config.playerA} fontSize={props.config.theme.fontSize}/>
                                </div> 
                            </div>
                            <div className={props.config.styleClass.nameItemDiv}>  
                                <div className={props.config.styleClass.oddNameDiv + ' ' +localClasses.playerNameDiv} >
                                    <ExtendedNameFild name={props.config.playerB} fontSize={props.config.theme.fontSize}/>
                                </div> 
                            </div> 
                        </div>
                        <div className={props.config.styleClass.columnContainer} > 
                            <div className={props.config.styleClass.nameItemDiv}>
                                <div className={props.config.styleClass.oddNameDiv + ' ' + localClasses.pointResultDiv + ' ' + localClasses.setNumberDiv} >
                                    Last 12
                                </div>
                            </div>
                            <div className={props.config.styleClass.nameItemDiv}>
                                <div className={props.config.styleClass.evenNameDiv + ' ' + 
                                                localClasses.pointResultDiv} >
                                    {"" + props.config.last16A + "%"} 
                                </div> 
                            </div>
                            <div className={props.config.styleClass.nameItemDiv}>  
                                <div className={props.config.styleClass.evenNameDiv + ' ' + 
                                                localClasses.pointResultDiv 
                                                } >
                                    {"" +  props.config.last16B + "%"} 
                                </div> 
                            </div> 
                        </div>
                        <div className={props.config.styleClass.columnContainer} > 
                            <div className={props.config.styleClass.nameItemDiv}>
                                <div className={props.config.styleClass.evenNameDiv + ' ' + localClasses.pointResultDiv + ' ' + localClasses.setNumberDiv} >
                                    Last 24
                                </div>
                            </div>
                            <div className={props.config.styleClass.nameItemDiv}>
                                <div className={props.config.styleClass.oddNameDiv + ' ' + 
                                                localClasses.pointResultDiv } >
                                    {"" + props.config.last32A + "%"} 
                                </div> 
                            </div>
                            <div className={props.config.styleClass.nameItemDiv}>  
                                <div className={props.config.styleClass.oddNameDiv + ' ' + 
                                                localClasses.pointResultDiv } >
                                    {"" + props.config.last32B + "%"} 
                                </div> 
                            </div> 
                        </div>
                        {props.config.showServe && <div className={props.config.styleClass.columnContainer} > 
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={props.config.styleClass.oddNameDiv + ' ' + localClasses.pointResultDiv + ' ' + localClasses.setNumberDiv} >
                                        When Serve
                                    </div>
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={props.config.styleClass.evenNameDiv + ' ' + 
                                                    localClasses.pointResultDiv } >
                                        {"win " + props.config.winInServeA + "%"} 
                                    </div> 
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>  
                                    <div className={props.config.styleClass.evenNameDiv + ' ' + 
                                                    localClasses.pointResultDiv } >
                                        {"win " + props.config.winInServeB + "%"} 
                                    </div> 
                                </div> 
                            </div>
                        }
                        {!props.config.showServe && <div className={props.config.styleClass.columnContainer} > 
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={props.config.styleClass.oddNameDiv + ' ' + localClasses.pointResultDiv + ' ' + localClasses.setNumberDiv} >
                                        Last 36
                                    </div>
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>
                                    <div className={props.config.styleClass.evenNameDiv + ' ' + 
                                                    localClasses.pointResultDiv } >
                                        {"" + props.config.last64A + "%"} 
                                    </div> 
                                </div>
                                <div className={props.config.styleClass.nameItemDiv}>  
                                    <div className={props.config.styleClass.evenNameDiv + ' ' + 
                                                    localClasses.pointResultDiv } >
                                        {"" + props.config.last64B + "%"} 
                                    </div> 
                                </div> 
                            </div>
                        }
                    </div>
                </div>
            </Zoom>
        </>
    )
}

export default Type4Scoreboard;

const useStyles = makeStyles((theme) => ({
    centerContainer: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        left: ( props ) => props.width/2 - 340, //'30px',
        top: ( props ) => props.height - props.border - props.scoreTotalHeigh, //'30px',
    },
    pointResultDiv: {
        //width: (props) => props.textFontSize*8,
        textAlign: 'center',
        paddingLeft: (props) => props.textFontSize/2 + props.borderRadius,
        paddingRight: (props) => props.textFontSize/2 + props.borderRadius,
    },
    playerNameDiv:{
        minWidth: (props) => props.textFontSize*8, 
        paddingLeft: (props) => props.textFontSize/2 + props.borderRadius,
        paddingRight: (props) => props.textFontSize/2 + props.borderRadius,
    },
    stageContainer:{
        display: "flex",
        flexDirection: "row",
        alignItems : "flex-start",
        float: 'left',
        justifyContent: 'space-between',
    },
    setNumberDiv: {
        marginTop: (props) => props.textFontSize*0.25 - props.verticalMargin,
        paddingTop: (props) => props.textFontSize*0.2,
        marginLeft: (props) => props.textFontSize/16 + props.borderRadius,
        marginRight: (props) => props.textFontSize/16 + props.borderRadius,
        paddingBottom: 0,
    },
    winner: {
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    serveDiv: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        fontSize: (props) => props.textFontSize*1.75,
        fontWeight: 'bold',
        paddingTop: (props) => props.textFontSize/32,
        paddingBottom: (props) => props.textFontSize/4-props.textFontSize/32,
        width: (props) => props.textFontSize*1.25,
        height: (props) => props.textFontSize*1.75-props.textFontSize/4,
        marginRight: 0, 
    },
    timeoutDiv: {
        backgroundColor: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        color: (props) => (props) => props.primaryColor, //'#111111', //'#003f72',
        fontSize: (props) => props.textFontSize*0.8,
        paddingTop: (props) => props.textFontSize/16,
        paddingBottom: (props) => props.textFontSize/16,
        paddingLeft: (props) => props.textFontSize/4,
        paddingRight: (props) => props.textFontSize/4,
    },
}))
