import React, { useContext, useEffect, useState, useRef } from 'react';
import {withRouter, Redirect} from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import '@fontsource/roboto';
import { GameContext } from '../entities/GameContext'
import logo from '../statics/images/kandolab-logo_v1.png';
import { Grid, Button, Paper, IconButton } from '@material-ui/core';

import { TextField } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Home from './Home'
import Alert from './components/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { ReactComponent as TShirt } from '../statics/images/t-shirt.svg';
import { ExpandLessSharp } from '@material-ui/icons';
import md5 from "md5";
import ControlDashboard from './ControlDashboard';
import BestPointDashboard from './BestPointDashboard';
import patrocinadores from '../statics/images/patrocinadores_resized.gif';

import {useSessionStorage} from "react-use-storage";

import Flags from "country-flag-icons/react/3x2";
import { hasFlag } from 'country-flag-icons'

import { Collapse, Zoom, Fade, Grow, Slide } from '@material-ui/core';
import Type1Scoreboard from './components/Type1Scoreboard'
import Type2Scoreboard from './components/Type2Scoreboard'
import Type3Scoreboard from './components/Type3Scoreboard'
import Type4Scoreboard from './components/Type4Scoreboard'
import Type5Scoreboard from './components/Type5Scoreboard'
import Type6Scoreboard from './components/Type6Scoreboard'

import {getScoreboardConfig} from '../services/backendapi.js'

//const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEVEL : window.API_URL
const baseURLV2 = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_V2_URL_DEVEL : process.env.REACT_APP_API_V2_URL
const baseReflectorURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REFLECTOR_URL_DEVEL : process.env.REACT_APP_REFLECTOR_URL

const CLOCK = 0x0001
const SPONSOR = 0x0002
const SPECIAL_MOMENT_FLAG = 0x0004
const SERVE = 0x0008
const CLOTH = 0x0010
const COMPETITION_STAGE = 0x0020
const TEAM_RESULT = 0x0040
const GAME_RESULT = 0x0080
const SCOREBOARD_BACKGROUND = 0x0100

function computeStats(pointTracking, currentMatch) {
    let last16A = 0
    let last32A = 0
    let last64A = 0
    let winInServeA = 0
    let winInServeB = 0
    let lostInServeA = 0
    let lostInServeB = 0
    /*
        let track = {
        "playerA": playerAName[currentMatch],
        "playerB": playerBName[currentMatch],
        "teamA": teamAName,
        "teamB": teamBName,
        "teamAPoint": teamAResult,
        "teamBPoint": teamBResult,
        "match": currentMatch,
        "game": currentGame,
        "pointA": isA?n:(playerAPoint[currentMatch][currentGame] || 0),
        "pointB": isA?(playerBPoint[currentMatch][currentGame] || 0):n,
        "playerAWin": isA, 
        "serving": serving, 
        "diff": diff
    }*/

    let i = 0
    let last16Count = 0
    let last32Count = 0
    let last64Count = 0
    while (i < pointTracking.length) {
        let p = pointTracking[pointTracking.length - i - 1]
        if (p.match !== currentMatch) {
            break
        }
        if (i < 12) {
            last16A += (p.playerAWin?1:0)
            last16Count ++
        }
        if (i < 24) {
            last32A += (p.playerAWin?1:0)
            last32Count ++ 
        }
        if (i < 36) {
            last64A += (p.playerAWin?1:0)
            last64Count ++
        }
        if (p.serving) {
            if (p.playerAWin) {
                winInServeA += 1
            }else{
                winInServeB += 1
            }
        }else{
            if (p.playerAWin) {
                lostInServeB += 1
            }else{
                lostInServeA += 1
            }
        }
        i ++;
    }
    if (last16Count > 0) {
        last16A = last16A * 100.0 / last16Count
    }
    if (last32Count > 0) {
        last32A = last32A * 100.0 / last32Count
    }
    if (last64Count > 0) {
        last64A = last64A * 100.0 / last64Count
    }
    if ((winInServeA + lostInServeA) > 0) {
        winInServeA = winInServeA * 100.0 / (winInServeA + lostInServeA)
    }
    if ((winInServeB + lostInServeB) > 0) { 
        winInServeB = winInServeB * 100.0 / (winInServeB + lostInServeB)
    }
    return {
            last16A: last16Count>0?last16A.toFixed(1):0, 
            last32A: last32Count>0?last32A.toFixed(1):0, 
            last64A: last64Count>0?last64A.toFixed(1):0,
            last16B: last16Count>0?(100.0-last16A).toFixed(1):0,
            last32B: last32Count>0?(100.0-last32A).toFixed(1):0,
            last64B: last64Count>0?(100.0-last64A).toFixed(1):0,
            winInServeA: winInServeA==0?0:winInServeA.toFixed(1), 
            winInServeB: winInServeB==0?0:winInServeB.toFixed(1)
        }
}

function computeTeamState(gameStatus) {
    var tAResult = 0
    var tBResult = 0
    var pAGPoint = 0
    var pBGPoint = 0
    var currentGameFinalA = 0
    var currentGameFinalB = 0
    
 
    let currentMatch = gameStatus.currentMatch
    let currentGame = gameStatus.currentGame

    for (let match=0 ; (match<gameStatus.matches.length && match<=currentMatch) ; match ++){
        var aGPoint = 0
        var bGPoint = 0
        
        for (let game=0 ; game<gameStatus.matches[match].points.length; game ++ ) {
            let pointA = gameStatus.matches[match].points[game].playerA
            let pointB = gameStatus.matches[match].points[game].playerB

            if (match === currentMatch) {
                if (game === currentGame) {
                    if (pointA>10 || pointB > 10){
                        if (Math.abs(pointA - pointB) >= 2){
                            if (pointA > pointB){
                                currentGameFinalA = 1
                            }else{
                                currentGameFinalB = 1
                            }
                        }
                    }

                    continue
                }
            }
            if (pointA>10 || pointB > 10){
                if (Math.abs(pointA - pointB) >= 2){
                    if (pointA > pointB){
                        aGPoint += 1
                    }else{
                        bGPoint += 1
                    }
                }
            }
            //console.log("Match: ", match, "Game: ", game, "A: ", aGPoint, "B: ", bGPoint, "T:", setNumberToWin)
        }
        let setNumberToWin = parseInt(gameStatus.matches[match].points.length / 2 )
        if (aGPoint > setNumberToWin || bGPoint > setNumberToWin) {
            if (aGPoint > bGPoint) {
                tAResult += 1
            }else{
                tBResult += 1
            }
        }
        if (match === currentMatch) {
            pAGPoint = aGPoint
            pBGPoint = bGPoint
        }
    }
    return {gamePointA: pAGPoint, 
            gamePointB: pBGPoint, 
            currentGamePointA: currentGameFinalA, 
            currentGamePointB: currentGameFinalB,
            teamPointA: tAResult,
            teamPointB: tBResult}
}

function Feed(props) {
    const [ id, setId ] = useState(null)
    const [ message, setMessage ] = useState(null)
    const [ gameStatus, setGameStatus] = useState(null)
    const [ gameStatusVisible, setGameStatusVisible ] = useState(true)
    const [ teamStatusVisible, setTeamStatusVisible ] = useState(true)
    const [ nameAlwaysVisible, setNameAlwaysVisible ] = useState(false)
    const [ teamAFlag, setTeamAFlag ] = useState("")
    const [ teamBFlag, setTeamBFlag ] = useState("")
    const [ timeVisible, setTimeVisible ] = useState(false)
    const [ specialMomentVisible, setSpecialMomentVisible ] = useState(false)
    const [ competitionStageVisible, setCompetitionStageVisible ] = useState(false)
    const [ scoreboardBackVisible, setScoreboardBackVisible ] = useState(false)
    
    const [ scoreboardType, setScoreboardType ] = useState(0x0)

    const [ elementState, setElementState ] = useState(0x0)

    const [ sponsorVisible, setSponsorVisible ] = useState(false)
    const [ showServe, setShowServe ] = useState(true)
    const [ showTshirt, setShowTshirt ] = useState(true)
    
    const [ currentTime, setCurrentTime ] = useState("")
    const [ lastUpdateTime, setLastUpdateTime ] = useState(0)
    const [ imageUrl, setImageUrl ] = useState("")
    const [ videoUrl, setVideoUrl ] = useState("")
    const [ backImageUrl, setBackImageUrl ] = useState("")

    const [primaryColor, setPrimaryColor] = useSessionStorage("primary_color", "#111111")
    const [secondaryColor, setSecondaryColor] = useSessionStorage("secondary_color", "#bbbbbb")
    const [backgroundColor, setBackgroundColor] = useSessionStorage("background_color", "#00ff00")
    const [sponsorImageSize, setSponsorImageSize] = useSessionStorage("sponsor_image_size", 200)
    
    const ws = useRef(null)
    const timer = useRef(null)
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    var theme = {
        "backgroundColor": backgroundColor,
        "primaryColor" : primaryColor,
        "secondaryColor" : secondaryColor,
        "width": 1920,
        "height": 1080,
        "border": 1080 * 0.05,
        "scoreTotalHeigh": 125,
        "borderRadius": 2,
        "textFontSize": 22,
        "verticalMargin": 3,
    }
    const classes = useStyles(theme);
    const scoreboardClasses = scoreboardStyles(theme);

    useEffect(() => {
        const id = props.match.params.id
        const r =  props.match.params.r
        const pass =  props.match.params.p
        console.log("Check token: ", props.match.params, id, r, pass)
        let hash = md5("" + id + "@" + r).substring(0,5)
        if (pass == hash || !props.control)
        {
            setId(id)
        }else{
            console.log(props.match.params, hash)
        }
    }, [])

    useEffect(() => {
        if (id === null) {
            return
        }
        // call getScoreboardConfig
        getScoreboardConfig(id).then((response) => {
            console.log("getScoreboardConfig: ", response)
            let timeStamp = Date.now() / 1000
            if (response.type === "state"){
                handleScoreboardConfig(response.content, timeStamp)
            }
        }).catch((error) => {
            console.log("getScoreboardConfig error: ", error)
        })
    }, [id])

    useEffect(() => {
        if (id === null) {
            return
        }

        if (waitingToReconnect) {
            return;
        }

        var wsCurrent = null

        console.log("Let to connect to Reflector")

        if (!ws.current) {
            var base = ""
            if (baseReflectorURL.substring(0, 8) === "https://"){
                base = "wss://" + baseReflectorURL.substring(8,baseReflectorURL.length);
            }else if(baseReflectorURL.substring(0, 7) === "http://"){
                base = "ws://" + baseReflectorURL.substring(7,baseReflectorURL.length);
            }else{
                base = baseReflectorURL;
            }

            ws.current = new WebSocket(base + "api/v1/reflect/"+id);
            wsCurrent = ws.current

            ws.current.onopen = () => {
                timer.current = setTimeout(() => {
                    console.log("IN OPEN: No ping received in 1 minute, closing websocket");
                    if (ws && ws.current){
                        ws.current.close();
                    }
                }, 60000);   
                setIsOpen(true)
                console.log("ws opened");
            }
            ws.current.onclose = () => {
                if (ws.current) {
                    console.log("ws closed by server")
                }else{
                    console.log("ws closed by component unmount")
                    return
                }
                if (waitingToReconnect) {
                    return
                }
                setIsOpen(false)
                console.log("ws closed");

                setWaitingToReconnect(true)

                setTimeout(() => setWaitingToReconnect(null), 5000);
            }
        }

        return () => {
            wsCurrent.close();
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
            ws.current = null;
        };
    }, [waitingToReconnect, id]);

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = function (event) {
            const data = JSON.parse(event.data);
            let timeStamp = Date.now() / 1000
            console.log("New data:", data, timeStamp)

            if (data.type === 'matchStatus') {
                setGameStatus(data.content)
                setLastUpdateTime(timeStamp)
            }
            if (data.type === 'state') {
                handleScoreboardConfig(data.content, timeStamp)
            }
            if (data.type === "ping") {
                console.log(">>> PING", timeStamp)
                try{
                    if (timer.current) {
                        clearTimeout(timer.current);
                        timer.current = null;
                        timer.current = setTimeout(() => {
                            console.log("IN PING: No ping received in 30 seconds, closing websocket");
                            if (ws && ws.current){
                                console.log("Let close the websocket")
                                ws.current.close();
                            }
                        }, 30000);
                    }else{
                        console.log("Timer is null")
                    }
                    ws.current.send(JSON.stringify({type: "pong"}))
                }catch(e){
                    console.log("Error happend:", e)
                    ws.current.close()
                }
            }
        };
    }, [isOpen]);

    useEffect(() => {
        if (!props.control)
        {
            setInterval(() => {
                let d = new Date();
                let timeString = ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
                setCurrentTime(timeString)
            }, 1000); 
        }
    }, [])

    useEffect(() => {
        if((elementState & TEAM_RESULT) > 0){
            setTeamStatusVisible(true)
        }else{
            setTeamStatusVisible(false)
        }
        if((elementState & GAME_RESULT) > 0){
            setGameStatusVisible(true)
        }else{
            setGameStatusVisible(false)
        }
        if((elementState & CLOTH) > 0){
            setShowTshirt(true)
        }else{
            setShowTshirt(false)
        }
        if((elementState & SERVE) > 0){
            setShowServe(true)
        }else{
            setShowServe(false)
        }
        if((elementState & SPONSOR) > 0){
            setSponsorVisible(true)
        }else{
            setSponsorVisible(false)
        }
        if((elementState & CLOCK) > 0){
            setTimeVisible(true)
        }else{
            setTimeVisible(false)
        }
        if((elementState & SPECIAL_MOMENT_FLAG) > 0){
            setSpecialMomentVisible(true)
        }else{
            setSpecialMomentVisible(false)
        }
        if((elementState & COMPETITION_STAGE) > 0){
            setCompetitionStageVisible(true)
        }else{
            setCompetitionStageVisible(false)
        }
        if((elementState & SCOREBOARD_BACKGROUND) > 0){
            setScoreboardBackVisible(true)
        }else{
            setScoreboardBackVisible(false)
        }
        let scoreboardT = (elementState & 0xF000) >> 12
        
        setScoreboardType(scoreboardT)
        console.log("Scoreboard type new: ", scoreboardT, "elementState: ", elementState)
        

    }, [elementState])

    const handleScoreboardConfig = (config, timeStamp) => {
        if ("primary_color" in config) {
            setPrimaryColor(config.primary_color)
        }
        if ("secondary_color" in config) {
            setSecondaryColor(config.secondary_color)
        }
        if ("background_color" in config) {
            setBackgroundColor(config.background_color)
        }
        if ("sponsor_image_size" in config) {
            setSponsorImageSize(config.sponsor_image_size)
        }
        if ("sc_team_a_flag" in config) {
            setTeamAFlag(config.sc_team_a_flag)
        }
        if ("sc_team_b_flag" in config) {
            setTeamBFlag(config.sc_team_b_flag)
        }
        if ("layer0_url" in config) {
            let url = baseURLV2 + "api/v2/cameras/assets/" + id + "/image.gif?r=" + config.layer0_url
            setImageUrl(url)
            let backUrl = baseURLV2 + "api/v2/cameras/assets/" + id + "/scoreboard.png?r=" + config.layer0_url
            setBackImageUrl(backUrl)
            let videoUrl = baseURLV2 + "api/v2/cameras/assets/" + id + "/video.mp4?r=" + config.layer0_url
            setVideoUrl(videoUrl)
        }
        setElementState(config.elementState)
        
        setLastUpdateTime(timeStamp)
    }

    

    var currentMatch = 0
    var currentGame = 0 
    var playerA = "PLAYER A"
    var playerB = "PLAYER B"
    var pointA = 0
    var pointAFull = []
    var pointB = 0
    var pointBFull = []
    var playerAServeFirstInThisGame = false
    var playerAServe = false
    var teamAColor = null
    var teamBColor = null
    var teamAName = ""
    var teamBName = ""
    var gamePointA  = 0
    var currentGamePointA = 0
    var currentGamePointB = 0
    var gamePointB  = 0
    var teamPointA  = 0
    var teamPointB  = 0
    var playerATimeout = false
    var playerBTimeout = false
    var innactive = false
    var current = Date.now()/1000
    var specialMoment = ""
    var stageStatus = ""
    var playerACards = 0
    var playerBCards = 0
    var expediteMode = false
    var stats = {}
    

    if (gameStatus !==  null)
    {
        currentMatch = gameStatus.currentMatch || 0
        currentGame = gameStatus.currentGame || 0
        let setNumber = gameStatus.control.setNumber || 10000

        stageStatus = gameStatus.stageStatus || ""
        
        playerA = gameStatus.matches[currentMatch].playerA //.substring(0,19)
        playerB = gameStatus.matches[currentMatch].playerB //.substring(0,19)
        pointA = gameStatus.matches[currentMatch].points[currentGame].playerA
        pointB = gameStatus.matches[currentMatch].points[currentGame].playerB

        for (let i = 0;  i<= currentGame ; i++) {
            pointAFull.push(gameStatus.matches[currentMatch].points[i].playerA)
            pointBFull.push(gameStatus.matches[currentMatch].points[i].playerB)
        }

        playerATimeout = gameStatus.matches[currentMatch].playerATimeout
        playerBTimeout = gameStatus.matches[currentMatch].playerBTimeout
        playerACards = gameStatus.matches[currentMatch].playerACards
        playerBCards = gameStatus.matches[currentMatch].playerBCards
        expediteMode = gameStatus.expediteMode
        

        teamAColor = gameStatus.teamAColour
        teamBColor = gameStatus.teamBColour
        teamAName = gameStatus.teamA
        teamBName = gameStatus.teamB

        let playerAServeFirstInThisMatch = gameStatus.matches[currentMatch].playerAServe
        if ((currentGame%2) === 1) {
            playerAServeFirstInThisGame = !playerAServeFirstInThisMatch
        }else{
            playerAServeFirstInThisGame = playerAServeFirstInThisMatch
        }
        if (((pointA < 10 || pointB < 10)) && !expediteMode) {
            if ((pointA + pointB) % 4 === 0 || (pointA + pointB) % 4 === 1) {
                playerAServe = playerAServeFirstInThisGame
            }else{
                playerAServe = !playerAServeFirstInThisGame
            }
        }else{
            if ((pointA + pointB) % 2 === 0) {
                playerAServe = playerAServeFirstInThisGame
            }else{
                playerAServe = !playerAServeFirstInThisGame
            }
        }
        var general = computeTeamState(gameStatus)
        gamePointA = general.gamePointA
        gamePointB = general.gamePointB
        teamPointA = general.teamPointA
        teamPointB = general.teamPointB
        currentGamePointA = general.currentGamePointA
        currentGamePointB = general.currentGamePointB

        if ((current - lastUpdateTime) > (5*60)) {
            innactive = true
        }

        stats = computeStats(gameStatus.pointTracking, currentMatch)

        //console.log("setnumber", setNumber/2, currentGame)

        if (pointA > 9 || pointB > 9) {
            if (Math.abs(pointA-pointB) > 0) {
                specialMoment = "GAME POINT"
                //console.log("Especial moments:", currentGame, setNumber, pointA, pointB, general)
                if ((currentGame + 1) > setNumber/2){
                    if ((pointA > pointB) && ((gamePointA+1) >= setNumber/2)){
                        specialMoment = "MATCH POINT"
                    }
                    if ((pointB > pointA) && ((gamePointB+1) >= setNumber/2)){
                        specialMoment = "MATCH POINT"
                    }
                }
            }
        }
    }

    var scoreboardConfig = {
        id: id,
        innactive: innactive,
        gameStatusVisible: gameStatusVisible,
        teamStatusVisible: teamStatusVisible,
        gameStatus: gameStatus,
        competitionStageVisible: competitionStageVisible,
        scoreboardBackVisible: scoreboardBackVisible,
        stageStatus: stageStatus,
        teamAName: teamAName,
        teamAColor: teamAColor,
        teamPointA: teamPointA,
        teamBName: teamBName,
        teamBColor: teamBColor,
        teamPointB: teamPointB,
        playerA: playerA + " " +teamAFlag,
        playerB: playerB + " " +teamBFlag,
        gamePointA: gamePointA,
        gamePointB: gamePointB,
        pointA: pointA,
        pointB: pointB,
        pointAFull: pointAFull,
        pointBFull: pointBFull,
        playerATimeout: playerATimeout, 
        playerBTimeout: playerBTimeout,
        playerACards: playerACards,
        playerBCards: playerBCards, 
        showServe: showServe,
        playerAServe: playerAServe, 
        styleClass: scoreboardClasses,
        showTshirt: showTshirt,
        theme: theme,
        specialMomentVisible: specialMomentVisible, 
        specialMoment: specialMoment,
        videoUrl: videoUrl,
        imageUrl: imageUrl, 
        backImageUrl: backImageUrl,
        last16A: stats.last16A,
        last16B: stats.last16B,
        last32A: stats.last32A,
        last32B: stats.last32B,
        last64A: stats.last64A,
        last64B: stats.last64B,
        winInServeA: stats.winInServeA,
        winInServeB: stats.winInServeB,
    }

    //console.log("scoreboardType: ", scoreboardType, scoreboardConfig)

    if (id) {
        if (props.control)
        {
            if (props.best_pont) {
                return(
                    <BestPointDashboard status={gameStatus} scoreboard={scoreboardConfig} baseUrl={baseURLV2 + "api/v2/cameras/"} cameraId={id}/>
                )
            }else{
                return(
                    <ControlDashboard status={gameStatus} baseUrl={baseURLV2 + "api/v2/cameras/"} cameraId={id}/>
                )
            }
        }else
        {
            return (
            <div className={classes.pageContent}   >
                {scoreboardType!=0x00 && <Slide direction="down" in={sponsorVisible}>
                    <div className={classes.adsContainer} >
                        <img src={imageUrl} className={classes.adsImage} style={{"width": sponsorImageSize}}/>
                    </div>
                </Slide> }

                {scoreboardType!=0x00 && <div className={classes.timeContainer} >
                    <Slide direction="down" in={timeVisible}>
                        <div className={classes.item}>
                            <div className={classes.timeItem} >
                                {currentTime}
                            </div>
                        </div>
                    </Slide>
                </div>}

                {scoreboardType==0x01 && 
                    <div className={classes.currentGame}  >
                        <Type1Scoreboard config={scoreboardConfig}/>
                    </div>
                }
                {scoreboardType==0x02 && 
                    <div className={classes.currentGame} >
                        <Type2Scoreboard config={scoreboardConfig}/>
                    </div>
                }
                {scoreboardType==0x03 && 
                    <div className={classes.currentGame} >
                        <Type3Scoreboard  config={scoreboardConfig}/>
                    </div>
                }

                {scoreboardType==0x04 && 
                    <div className={classes.currentGame} >
                        <Type4Scoreboard config={scoreboardConfig}/>
                    </div>
                }
                {scoreboardType==0x05 && 
                    <div className={classes.currentGame} >
                        <Type5Scoreboard config={scoreboardConfig}/>
                    </div>
                }
                {scoreboardType==0x06 && 
                    <div className={classes.currentGame} >
                        <Type6Scoreboard/>
                    </div>
                }
            </div>
            )
        }
    }else{
        return (
            <div className={classes.pageContent}   >
                Not valid url
            </div>
        )
    }
}

export default withRouter(Feed);

const scoreboardStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems : "flex-start",
        float: 'left',
    },

    stageDiv: {
        backgroundColor: (props) => props.secondaryColor, //'#111111', //'#003f72',
        color: (props) => props.primaryColor, //'#bbbbbb', //'#dae5ed',

        textTransform: 'uppercase',
        fontSize: (props) => props.textFontSize * 0.8,
        marginTop: 0,
        borderBottomLeftRadius: (props) => props.borderRadius,
        borderBottomRightRadius: (props) => props.borderRadius,
        paddingLeft: (props) => props.textFontSize/2,
        paddingRight: (props) => props.textFontSize/2,
        paddingTop: (props) => props.textFontSize/8,
        paddingBottom: (props) => props.textFontSize/8,
    },

    nameContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        float: 'left',
        margin: 0,
    },

    columnContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },

    scoreboardBackImg:{
        marginTop: (props) => props.verticalMargin,
        height: (props) => (props.textFontSize * 1.75) * 2 + props.verticalMargin,
    },

    nameItemDiv: {
        marginTop: (props) => props.verticalMargin,
        marginBottom: '0px',
        marginLeft: (props) => 0-props.borderRadius,
        height: (props) => props.textFontSize * 1.75,
    },

    tshirtDiv: {
        height: (props) => props.textFontSize * 1.75-props.textFontSize/4,
        width: (props) => props.textFontSize/2+props.borderRadius,
        borderRadius: (props) => props.borderRadius,
        paddingTop: (props) => props.textFontSize/4,
        paddingBottom: 0,
    },

    oddNameDiv: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        height: (props) => props.textFontSize * 1.75-props.textFontSize/4,

        textTransform: 'uppercase',
        fontSize: (props) => props.textFontSize,

        borderRadius: (props) => props.borderRadius,
        paddingTop: (props) => props.textFontSize/4,
        paddingBottom: 0,
    },

    evenNameDiv:{
        backgroundColor: (props) => props.secondaryColor, //'#111111', //'#003f72',
        color: (props) => props.primaryColor, //'#bbbbbb', //'#dae5ed',
        height: (props) => props.textFontSize * 1.75-props.textFontSize/4,
        
        textTransform: 'uppercase',
        fontSize: (props) => props.textFontSize,

        borderRadius: (props) => props.borderRadius,
        paddingTop: (props) => props.textFontSize/4,
        paddingBottom: 0,
    },
}))

const useStyles = makeStyles((theme) => ({
    pageContent: {
        //padding: theme.spacing(0, 2),
        backgroundColor: ( props ) => props.backgroundColor, //"#00ff00",
        width: ( props ) => props.width, //'1920px',
        height: ( props ) => props.height, //'1080px',
        margin: '0px'
    },
    adsContainer:{
        position: "fixed",
        left: ( props ) => props.border, //'30px',
        top: ( props ) => props.border, //'30px',
    },
    adsImage: {
        width: "200px"
    },
    imageContainer:{
        position: "fixed",
        left: "0%",
        top: "0%",
        width: "100%",
        heigh: "100%",
    },
    item: {
        marginTop: '5px',
        height: '40px',
    },
    image: {
        width: "100%",
        transition: "opacity 3s linear",
    },

    currentGame: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        left: ( props ) => props.border, //'30px',
        top: ( props ) => props.height - props.border - props.scoreTotalHeigh, //'30px',
    },

    timeContainer: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        width: 125,

        left: ( props ) => props.width - props.border - 125, //'30px',
        top: ( props ) => props.border, //'30px',

        fontSize: "32px",
    },

    timeItem: {
        backgroundColor: (props) => props.primaryColor, //'#111111', //'#003f72',
        color: (props) => props.secondaryColor, //'#bbbbbb', //'#dae5ed',
        width: '125px',
        height: '35px',
        fontSize: "30px",

        display: "inline",
        borderRadius: "3px",

        float: 'right',
        padding: '2px 10px 5px 10px',
    },
}));
